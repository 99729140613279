import React from 'react';
import { Autor } from '../../models/Autor';

import "../../theme/Hero.scss"

interface PropsHeroLayout{
    post:PropsPost;
}
interface PropsPost{
    // fluidDescription?: string;
    fluid?: any | null | undefined;
    categorySlug: string;
    category: string;
    title: string;
    content: string;
    author: string;
    authorObject: Autor;
    date: string;
    tags: any[] | null | undefined;
}

const HeroLayout = ({post}:PropsHeroLayout): JSX.Element => {

    console.log("[POST HERO]",post);

    // const srcSet = post.fluid?.gatsbyImageData.images.sources[0].srcSet;
    // const split_srcSet = srcSet.split(",");
    // const webp_last_size = split_srcSet.slice(-1)[0];
    // const webp = webp_last_size.split(" ")[0];

    // console.log("[POST WEBP]",webp);

    let highlightImg: any = undefined;

    try {
        highlightImg = post.fluid.gatsbyImageData.images.fallback.src;
    } catch (error) {
        try {
            highlightImg = post.fluid.gatsbyImageData.images.sources[0].srcSet;
        } catch (error2) {
            highlightImg = "https://vectoranalisis.mx/static/images/vector/analisis.png";
        }
    }

    return(
        <>
            <div className='wrap__hero'>
                <div className="hero__bg" style={{backgroundImage: "url("+highlightImg+")"}}>
                {/* <div className="hero__bg" style={{backgroundImage: "url("+webp+")"}}> */}
                    <div className={"filter " + (post.fluid ? "" :"flash")}></div>
                </div>
                <div className='hero'>
                    <div className='hero__content'>
                        <div className="tag">
                            <h2>{post.content}</h2>
                        </div>
                        <div className="title">
                            <h1>{post.title}.</h1>
                        </div>
                        <div className="autor">
                            <h2>{post.date}. {(post?.authorObject?.nombre + ".") || (post?.author + ".") || ""} {post?.authorExchangeMarket && post?.authorExchangeMarket?.nombre + "."} {post?.authorObjectElTecnicoOpina && post?.authorObjectElTecnicoOpina?.nombre + "."}</h2>
                        </div>
                        <div className="tags">
                            {
                            post.tags && post.tags.map((node)=>{
                                const {slug, nombre} = node;
                                return(
                                    <a key={slug} href={"/tags/"+slug}>
                                        <div className="tag">
                                            <p>{nombre}</p>
                                        </div>
                                    </a>
                                )
                            })
                            }
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};

HeroLayout.defaultProps = {
}

export default HeroLayout;